import { graphql } from "gatsby";
import Img from "gatsby-image";
import React from "react";
import Layout from "../components/layout";
import { KeofittAboutData } from "../model";
import Container from "../components/container";

export const query = graphql`
  query {
    image: file(name: { eq: "draabe" }) {
      id
      childImageSharp {
        fluid(maxWidth: 448) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    text: markdownRemark(frontmatter: { title: { eq: "THE DROP OF WATER" } }) {
      id
      frontmatter {
        title
      }
      html
    }
  }
`;

const About = ({ data }: { data: KeofittAboutData }) => {
  return (
    <Layout title="About" padding>
      <Container>
        <div className="max-w-md mx-auto" key={data.image.id}>
          <Img fluid={data.image.childImageSharp.fluid} />
        </div>

        <div className="py-8 fairytale">
          <h2 className="uppercase">{data.text.frontmatter.title}</h2>
          <div
            key={data.text.id}
            dangerouslySetInnerHTML={{ __html: data.text.html }}
          />
        </div>
      </Container>
    </Layout>
  );
};

export default About;
